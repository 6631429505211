import React from "react";
import { Grid, Text, Divider, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Position } from "./types";

const MotionBox = motion(Box);
const MotionGrid = motion(Grid);
interface Props {
  data: Position;
  style?: React.CSSProperties;
  process: string | null;
}

const InformationSection: React.FC<Props> = ({ data, style, process }) => {
  const glassmorphism = {
    bg: "rgba(255, 255, 255, 0.1)",
    backdropBlur: "5px",
    borderRadius: "10px",
    borderColor: "rgba(255, 255, 255, 0.18)",
    p: 5,
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  };

  const parentVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // delay between child animations
      },
    },
  };

  const childVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <MotionGrid
      gap={6}
      templateColumns={{ base: "1fr", md: "1fr 1fr" }}
      style={style}
      variants={parentVariants}
      initial="hidden"
      animate="visible"
    >
      {/* General Information */}
      <MotionBox
        gridColumn={{ base: "span 2/span 2", md: "span 1/span 1" }}
        {...glassmorphism}
        variants={childVariants}
      >
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          General Information
        </Text>
        <Text mb={2}>
          <b>Title:</b> {data.Title}
        </Text>
        <Divider borderColor="white" mb={2} />
        <Text mb={2}>
          <b>Company:</b> {data.Company}
        </Text>
        <Divider borderColor="white" mb={2} />
        <Text mb={2}>
          <b>Department:</b> {data.Department}
        </Text>
        <Divider borderColor="white" mb={2} />
        <Text mb={2}>
          <b>Location:</b> {data.Location}
        </Text>
      </MotionBox>

      {/* Role Specifics */}
      <MotionBox
        gridColumn={{ base: "span 2/span 2", md: "span 1/span 1" }}
        {...glassmorphism}
        variants={childVariants}
      >
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          Role Specifics
        </Text>
        <Text mb={2}>
          <b>Part Time:</b> {data.PartTime ? "Yes" : "No"}
        </Text>
        <Divider borderColor="white" mb={2} />
        <Text mb={2}>
          <b>Number of positions:</b> {data.Number}
        </Text>
        <Divider borderColor="white" mb={2} />
        <Text mb={2}>
          <b>Reporting Manager:</b> {data.ReportingManager}
        </Text>
        <Divider borderColor="white" mb={2} />
        <Text mb={2}>
          <b>Supervisor:</b> {data.Supervisor ? "Yes" : "No"}
        </Text>
        <Divider borderColor="white" mb={2} />
        <Text mb={2}>
          <b>Supervising:</b> {data.Supervising || "N/A"}
        </Text>
      </MotionBox>

      {/* Salary Details */}

      {/* If the process is pending approval, show the salary details, otherwise hide it */}
      {process === "Pending_Approval" && (
        <MotionBox
          gridColumn={{ base: "span 2/span 2", md: "span 1/span 1" }}
          {...glassmorphism}
          variants={childVariants}
        >
          <Text fontSize="2xl" fontWeight="bold" mb={4}>
            Salary Details
          </Text>
          <Text mb={2}>
            <b>Minimum Salary:</b> ${data.SalaryMin}
          </Text>
          <Divider borderColor="white" mb={2} />
          <Text mb={2}>
            <b>Mid-point Salary:</b> ${data.SalaryMid}
          </Text>
          <Divider borderColor="white" mb={2} />
          <Text>
            <b>Maximum Salary:</b> ${data.SalaryMax}
          </Text>
        </MotionBox>
      )}
      {/* New or Existing */}
      <MotionBox
        gridColumn={{ base: "span 2/span 2", md: "span 1/span 1" }}
        {...glassmorphism}
        variants={childVariants}
      >
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          {data.Existing ? "This Position Exists" : "This is a New Position"}
        </Text>
        <Text mb={2}>
          <b>Existing:</b> {data.Existing ? "Yes" : "No"}
        </Text>
        <Divider borderColor="white" mb={2} />
        <Text mb={2}>
          <b>Replacing Employee:</b> {data.ReplacingEmployee || "N/A"}
        </Text>
        <Divider borderColor="white" mb={2} />
        <Text mb={2}>
          <b>Ops Customer Account Name (if applicable):</b>{" "}
          {data.OpsClientAccount}
        </Text>
      </MotionBox>

      {/* Working Days */}
      <MotionBox
        gridColumn={{ base: "span 2/span 2", md: "span 2/span 2" }}
        {...glassmorphism}
        variants={childVariants}
      >
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          Working Days
        </Text>
        <Text>{data.WorkingDays}</Text>
      </MotionBox>
      {/* Business Justification */}
      {data.Justification && (
        <MotionBox
          gridColumn={{ base: "span 2/span 2", md: "span 2/span 2" }}
          {...glassmorphism}
          variants={childVariants}
        >
          <Text fontSize="2xl" fontWeight="bold" mb={4}>
            Business Justification
          </Text>
          {/* Render rich text using plain HTML */}
          <div dangerouslySetInnerHTML={{ __html: data.Justification }}></div>
        </MotionBox>
      )}
    </MotionGrid>
  );
};

export default InformationSection;
