import React from "react";
import { motion } from "framer-motion"; // Import motion from framer-motion
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
} from "@chakra-ui/react";

const modernGlassmorphismStyle = {
  backdropFilter: "blur(4px)",
  WebkitBackdropFilter: "blur(4px)",
  backgroundColor: "rgba(255, 255, 255, 0.05)", // Slightly visible frosted look
  borderRadius: "8px", // Rounded corners are modern
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Slight shadow for depth
  border: "1px solid rgba(255, 255, 255, 0.18)", // Subtle border to maintain structure
  color: "rgba(255, 255, 255, 0.95)",
};

export const AlertRequestClosed = () => (
  <motion.div
    initial={{ opacity: 0 }} // Initial state (hidden)
    animate={{ opacity: 1 }} // Animation state (visible)
    exit={{ opacity: 0 }} // Exit state (hidden)
  >
    <Alert
      status="error"
      borderRadius="0px"
      boxShadow="md"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="260px"
      padding="20px"
      sx={modernGlassmorphismStyle}
    >
      <Box marginBottom="10px">
        <AlertIcon boxSize="40px" color="white" />
      </Box>
      <AlertTitle
        fontWeight="bold"
        fontSize="xl"
        marginBottom="8px"
        color="white"
      >
        Action Expired
      </AlertTitle>
      <AlertDescription
        maxWidth="sm"
        marginBottom="16px"
        color="gray.300"
        fontSize="md"
      >
        Request status has been updated, closed, or cancelled. Please contact
        Process Improvement if you believe there's a mistake.
      </AlertDescription>
    </Alert>
  </motion.div>
);
