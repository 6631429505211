import { useState, useEffect } from "react";
import sendApiRequest, { APIPayload } from "../api/apiConfig";

type ApiResponse = {
  data: any;
  error: any;
  isLoading: boolean;
};

export const useApiRequest = (payload: APIPayload): ApiResponse => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const responseData = await sendApiRequest(payload);

        if (responseData.success === false) {
          setError("API Error: Request was not successful");
        } else {
          setData(responseData.data[0]);

          // Console log the data after a successful API call
          console.log("Fetched Data:", responseData.data[0]);
        }
      } catch (apiError) {
        setError(apiError);

        // Console log the error if there's an exception
        console.error("API Error:", apiError);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this useEffect runs only once after component mount

  return { data, error, isLoading };
};
