import { Box, Flex, Image, Text } from "@chakra-ui/react";

const TopBar = () => {
  return (
    <Flex
      align="center"
      bg="gray.700"
      boxShadow="lg"
      py={1}
      height="40px"
      css={{
        backdropFilter: "blur(10px)",
      }}
    >
      <Box mr={1}>
        <Image src="hirschbach logo.png" alt="Logo" maxH={10} />
      </Box>
      <Text
        fontSize="md" // Change text size to bigger (e.g., xl)
        fontWeight="medium"
        color="white"
      >
        {" | "}
        <span style={{ color: "lightblue" }}>Position</span>{" "}
        <span style={{ color: "white" }}>Request</span>
      </Text>
    </Flex>
  );
};

export default TopBar;
