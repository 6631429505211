import React, { useState } from "react";
import {
  Center,
  Grid,
  Box,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaSlackHash } from "react-icons/fa";
import { useApiRequest } from "../../hooks/useApiRequest";
import { ErrorAlert } from "./parts/alertMessages";
import LoadingComponent from "../global/loadingSpinner";
import InformationSection from "./parts/requestInformation";
import { Position, FloatingIconButtonProps } from "./parts/types";
import FormComponent from "./parts/businessJustification";
import ApprovalForm from "./parts/ceoApproval";
import { SuccessAlert } from "./parts/formSubmittedAlert";
import sendAPIRequest from "../../api/apiConfig";
import { useToast } from "@chakra-ui/react";
import { AlertRequestClosed } from "./parts/alertRequestClosed";
import { keyframes } from "@emotion/react";

//css for the spin animation for the floating icon button
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;
//function to get the parameters from the url
const getParamsFromURL = (
  paramNames: string[]
): Record<string, string | null> => {
  const params = new URLSearchParams(window.location.search);
  const paramValues: Record<string, string | null> = {};

  paramNames.forEach((paramName) => {
    paramValues[paramName] = params.get(paramName);
  });

  return paramValues;
};
//css for the glassmorphism effect for containers
const glassmorphismStyleForNavyBackground = {
  backdropFilter: "blur(16px) saturate(200%)",
  WebkitBackdropFilter: "blur(16px) saturate(200%)",
  backgroundColor: "rgba(17, 25, 40, 0.50)",
  borderRadius: "12px",
  border: "1px solid rgba(255, 255, 255, 0.125)",
  color: "rgba(255, 255, 255, 0.85)",
};

//main function for the dashboard
export default function Dashboard() {
  //Variables used on the dashboard
  const { ID, User, UserEmail, Process } = getParamsFromURL([
    "ID",
    "User",
    "UserEmail",
    "Process",
  ]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const [isInfoVisible, setIsInfoVisible] = useState(true);
  const [clicked, setClicked] = useState(false);
  const breakpoint = useBreakpointValue({
    base: "base",
    sm: "none",
    md: "none",
    lg: "none",
    xl: "none",
  });

  //function to handle the click of the floating icon button
  const onClickIconButton = () => {
    if (breakpoint === "base") {
      setIsInfoVisible((prev) => !prev);
      setClicked(true);
      setTimeout(() => setClicked(false), 500);
    }
  };

  //function to render the floating icon button
  const FloatingIconButton: React.FC<FloatingIconButtonProps> = ({
    breakpoint,
  }) => {
    if (breakpoint !== "base") return null;

    const glassmorphismStyleForWhiteBackground = {
      backdropFilter: "blur(8px) saturate(200%)",
      WebkitBackdropFilter: "blur(8px) saturate(200%)",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      borderRadius: "50%",
      border: "1px solid rgba(255, 255, 255, 0.125)",
      color: "rgba(0, 0, 0, 0.85)",
      animation: clicked ? `${spin} 0.5s` : "none",
    };

    return (
      <IconButton
        aria-label="Tasks"
        position="fixed"
        bottom="1rem"
        right="1rem"
        icon={<FaSlackHash size="24px" />}
        sx={glassmorphismStyleForWhiteBackground}
        size="lg"
        onClick={onClickIconButton}
      />
    );
  };

  //function to handle the submission of the business justification form
  const onSubmit = async (formData: any) => {
    setIsSubmitting(true);
    const payload = {
      id: ID,
      process: "Business Justification",
      user: User,
      userEmail: UserEmail,
      data: {
        status: formData.status,
        comment: formData.comment,
        number: formData.number,
      },
    };

    try {
      const response = await sendAPIRequest(payload);

      if (response.success) {
        console.log("Form submitted with status:", formData.status);
        setIsSubmitted(true);
      } else {
        throw new Error();
      }
    } catch (error) {
      toast({
        title: "Submission failed.",
        description: "Please try again or contact Process Improvement.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  //function to handle the submission of the CEO approval form
  const onSubmitApproval = async (formData: any) => {
    setIsSubmitting(true);
    const payload = {
      id: ID,
      process: "Approval",
      user: User,
      userEmail: UserEmail,
      data: {
        status: formData.status,
        comment: formData.comment,
        number: formData.number,
      },
    };

    try {
      const response = await sendAPIRequest(payload);

      if (response.success) {
        console.log("Form submitted with status:", formData.status);
        setIsSubmitted(true);
      } else {
        throw new Error();
      }
    } catch (error) {
      toast({
        title: "Submission failed.",
        description: "Please try again or contact Process Improvement.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  //function to get the data from the api for the dashboard
  const payload = {
    id: ID,
    process: "information",
  };

  const { data, error, isLoading } = useApiRequest(payload);

  if (isLoading) return <LoadingComponent />;
  if (error || !data) return <ErrorAlert />;

  const renderFormComponent = (data: Position) => {
    if (isSubmitting) {
      return <LoadingComponent />;
    }
    if (isSubmitted) {
      return <SuccessAlert />;
    }

    //if the status of the request is pending business justification and the process is pending business justification, render the business justification form, otherwise block further actions
    return data.Status === "Pending Business Justification" &&
      Process === "Pending_Business_Justification" ? (
      <FormComponent
        data={data}
        onSubmit={(status, comment, number) =>
          onSubmit({ status, comment, number })
        }
      />
    ) : data.Status === "Pending Approval" && Process === "Pending_Approval" ? (
      <ApprovalForm
        data={data}
        onSubmitApproval={(status, comment, number) =>
          onSubmitApproval({ status, comment, number })
        }
      />
    ) : (
      <AlertRequestClosed />
    );
  };

  //return the dashboard
  return (
    <Center py={4}>
      <Grid
        templateColumns={{ base: "1fr", md: "2fr 1fr" }}
        gap={{ base: 4, md: 6 }}
        w="98%"
        h="auto"
        maxWidth={{ base: "600px", md: "1000px", lg: "1600px" }}
      >
        {isInfoVisible && (
          <Box p={4} sx={glassmorphismStyleForNavyBackground}>
            <InformationSection data={data as Position} process={Process} />
          </Box>
        )}

        {(breakpoint === "base" && !isInfoVisible) || breakpoint !== "base" ? (
          <Box p={4} sx={glassmorphismStyleForNavyBackground}>
            {renderFormComponent(data as Position)}
          </Box>
        ) : null}
      </Grid>
      <FloatingIconButton breakpoint={breakpoint} />
    </Center>
  );
}
