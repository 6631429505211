import React from "react";
import { motion } from "framer-motion"; // Import motion from framer-motion
import styled from "@emotion/styled";
import HashLoader from "react-spinners/HashLoader";

const CenteredContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75); // Glassmorphism background color
  border-radius: 12px; // To give rounded edges
  border: 1px solid rgba(255, 255, 255, 0.125); // Border for the glass effect
  padding: 20px; // Adding some space around the loader
`;

const LoadingComponent = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }} // Initial state (hidden)
      animate={{ opacity: 1 }} // Animation state (visible)
      exit={{ opacity: 0 }} // Exit state (hidden)
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CenteredContainer>
        <HashLoader color="#FF5733" size={50} />
      </CenteredContainer>
    </motion.div>
  );
};

export default LoadingComponent;
