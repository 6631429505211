import React, { useState } from "react";
import { motion } from "framer-motion"; // Import motion from framer-motion
import {
  FormControl,
  FormLabel,
  Textarea,
  Select,
  Button,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Position } from "./types";

interface FormProps {
  data: Position;
  onSubmit: (status: string, comment?: string, number?: number) => void;
}

const FormComponent: React.FC<FormProps> = ({ data, onSubmit }) => {
  const toast = useToast();

  const [selectedStatus, setSelectedStatus] = useState("Approve");
  const [selectedComment, setSelectedComment] = useState("");
  const [selectedNumber, setSelectedNumber] = useState(data.Number || 1);

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSelectedComment(event.target.value);
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedNumber(Number(event.target.value));
  };

  const handleSubmit = () => {
    if (
      (selectedStatus === "Place On-Hold" || selectedStatus === "Reject") &&
      !selectedComment
    ) {
      toast({
        title: "Please provide a comment",
        status: "warning",
        duration: 3000,
        position: "top",
      });
      return;
    }

    if (selectedStatus === "Approve" && !selectedComment) {
      toast({
        title: "Please provide a business justification",
        status: "warning",
        duration: 3000,
        position: "top",
      });
      return;
    }

    onSubmit(selectedStatus, selectedComment, selectedNumber);
    console.log(selectedStatus, selectedComment, selectedNumber);
  };

  const shouldShowCommentField =
    selectedStatus === "Place On-Hold" ||
    selectedStatus === "Reject" ||
    selectedStatus === "Approve";

  return (
    <motion.div
      initial={{ opacity: 0 }} // Initial state (hidden)
      animate={{ opacity: 1 }} // Animation state (visible)
      exit={{ opacity: 0 }} // Exit state (hidden)
    >
      <VStack spacing={4}>
        <FormControl>
          <FormLabel>Options *</FormLabel>
          <Select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="Approve" style={{ color: "black" }}>
              Approve
            </option>
            <option value="Reject" style={{ color: "black" }}>
              Reject
            </option>
            <option value="Place On-Hold" style={{ color: "black" }}>
              Place On-Hold
            </option>
          </Select>
        </FormControl>

        {shouldShowCommentField && (
          <FormControl>
            <FormLabel>
              {selectedStatus === "Approve"
                ? "Business Justification *"
                : "Comment *"}
            </FormLabel>
            <Textarea
              placeholder={
                selectedStatus === "Approve"
                  ? "Enter your business justification"
                  : "Enter your comment"
              }
              _placeholder={{ color: "gray.50" }}
              value={selectedComment}
              onChange={handleCommentChange}
            />
          </FormControl>
        )}

        {selectedStatus === "Approve" && data.Number > 1 && (
          <FormControl>
            <FormLabel>Number of Positions</FormLabel>
            <Select value={selectedNumber} onChange={handleNumberChange}>
              {Array.from({ length: data.Number }, (_, index) => (
                <option
                  key={index + 1}
                  value={index + 1}
                  style={{ color: "black" }}
                >
                  {index + 1}
                </option>
              ))}
            </Select>
          </FormControl>
        )}

        <Button onClick={handleSubmit}>Submit</Button>
      </VStack>
    </motion.div>
  );
};

export default FormComponent;
