import axios from "axios";

export interface APIPayload {
  [key: string]: any;
}

const sendApiRequest = async (payload: APIPayload) => {
  const subscriptionKey = "15752a42619742d3ae03fef44b5f4b5c";
  const url = "https://process-api-01.azure-api.net/positionrequest/manual/paths/invoke";

  try {
    const response = await axios.post(url, payload, {
      headers: {
        "Ocp-Apim-Subscription-Key": subscriptionKey,
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("API request failed:", error);
    throw error; // Rethrow the error to handle it in the component
  }
};

export default sendApiRequest;
