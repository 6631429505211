import React from "react";
import TopBar from "./components/global/TopBar";
import ManagePage from "./components/main/ManagePage";

const App = () => {
  const appStyles = {
    backgroundImage: "url('/background.jpg')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    minHeight: "100vh",
  };

  return (
    <div style={appStyles}>
      <TopBar />
      <ManagePage />
    </div>
  );
};

export default App;
